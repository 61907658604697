import React from 'react';
import { useHistory } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import App from '../../App';

const Auth0ProviderWithHistory = () => {
  const history = useHistory();

  const onRedirectCallback = (appState) => {
    // if (!isEmpty(appState.lineItems)) {
    //   return createCheckoutSession(appState.lineItems, appState.email);
    // }

    history.push(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
  };

  return (
    <Auth0Provider
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      <App />
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
