/* eslint-disable operator-assignment */
/* eslint-disable max-len */
/* eslint-disable no-case-declarations */
/* eslint-disable no-param-reassign */
import React, {
  useReducer, useEffect, createContext, useContext,
} from 'react';
import produce from 'immer';
import axios from 'axios';
import ApiWrapper from '../helpers/api/api-wrapper';

export const Context = createContext();
export const useShop = () => useContext(Context);

const initialState = {
  loading: true,
  shopItemsDietaryPlans: [],
  shopItems: [],
  cartItems: [],
  isSidebarOpen: false,
  isAddCartItem: false,
  dataStripe: {},
  userData: {},
};

const reducerFn = (state, action) => {
  switch (action.type) {
    case 'loading':
      return { ...state, loading: action.loading };
    case 'setShopItems':
      return { ...state, shopItems: action.shopItems };
    case 'setshopItemsDietaryPlans':
      return { ...state, shopItemsDietaryPlans: action.shopItemsDietaryPlans };
    case 'setCartItems':
      return { ...state, cartItems: action.cartItems };
    case 'clearCartItems':
      return { ...state, cartItems: [] };
    case 'setIsSidebarOpen':
      return { ...state, isSidebarOpen: action.isSidebarOpen };
    case 'removeCartItem':
      return { 
        ...state, 
        cartItems: state.cartItems.filter((item) => !(item.id === action.cartItemId.id && item.type === action.cartItemId.type)) //(item.id !== action.cartItemId.id && item.type !== action.cartItemId.type)) 
      };
    case 'addCartItem':
      // check if item already exists
      const cartItemIndex = state.cartItems.findIndex(
        (item) => item.id === action.cartItem.id && item.type === action.cartItem.type,
      );

      if (cartItemIndex !== -1) {
        break;
        //state.cartItems[cartItemIndex].quantity = state.cartItems[cartItemIndex].quantity + action.cartItem.quantity;
      }
      return { ...state, cartItems: [action.cartItem, ...state.cartItems], isAddCartItem: true };
    case 'addDataStripe':
      return { ...state, dataStripe: action.dataStripe };
    case 'setUserData':
      return { ...state, userData: action.userData };
    case 'restoreCartItem':
      return { ...state, cartItems: state.cartItems.map(item => ({
        ...item,
        total_with_coupon: null
      })),
      isAddCartItem: false};
    default:
      throw new Error('Unknown shop reducer case');
  }
};

const reducer = produce(reducerFn);

export const ShopProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setLoading = (loading) => {
    dispatch({
      type: 'loading',
      loading,
    });
  };

  const setCartItems = (cartItems) => {
    dispatch({
      type: 'setCartItems',
      cartItems,
    });
  };

  const setShopItems = (shopItems) => {
    dispatch({
      type: 'setShopItems',
      shopItems,
    });
  };
  const setshopItemsDietaryPlans = (shopItemsDietaryPlans) => {
    dispatch({
      type: 'setshopItemsDietaryPlans',
      shopItemsDietaryPlans,
    });
  };
  const removeCartItem = (cartItemId) => {
    dispatch({
      type: 'removeCartItem',
      cartItemId,
    });
  };

  const addCartItem = (cartItem) => {
    dispatch({
      type: 'addCartItem',
      cartItem,
    });
  };

  const clearCartItems = () => {
    dispatch({
      type: 'clearCartItems',
    });
  };
  const setIsSidebarOpen = (isSidebarOpen) => {
    dispatch({
      type: 'setIsSidebarOpen',
      isSidebarOpen,
    });
  };
  const setDataStripe = (dataStripe) => {
    dispatch({
      type: 'addDataStripe',
      dataStripe,
    });
  };
  const setUserData = (userData) => {
    dispatch({
      type: 'setUserData',
      userData,
    });
  };
  const restoreCartItem = () => {
    dispatch({
      type: 'restoreCartItem'
    });
  };
  const getshopItemsDietaryPlans = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/billing/dietaryPlansShopItems`);
      // const { data } = await ApiWrapper.getDietaryPlans();
      setshopItemsDietaryPlans(data);
    } catch (err) {
      console.log('get DietaryPlans err: ', err);
    }
  };
  const getShopItems = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/billing/routineShopItems`);
      // const { data } = await ApiWrapper.getRoutines();
      const itemsDietaryPlans = getshopItemsDietaryPlans();
      setShopItems(data);
      await itemsDietaryPlans;
    } catch (err) {
      console.log('get routines err: ', err);
    }
    setLoading(false);
  };
  const getUserData = async (useId, email) => {
    try {
      const { data } = await ApiWrapper.getDataUser(useId, email);//await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/overview/${useId}/${email}`);
      setUserData(data);
    } catch (err) {
      console.log('get UserData err: ', err);
    }
  };
  const getChargeInfo = () => {
    const total = state.cartItems.reduce(
      (a, b) => a.price * a.quantity + b.price * b.quantity,
      {
        price: 0,
        quantity: 0,
      },
    ) * 100;

    const routines = state.cartItems.map(({ id, type }) => ({
      id,
      type,
    }));

    const description = `MadyFit - ${state.cartItems
      .map(({ title, quantity }) => `${title} (${quantity})`)
      .join(' + ')}`;

    return {
      total,
      description,
      routines,
    };
  };
  const sendCartItems = async (lineItems, userId, email, paymentId) => {
    setLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/billing/savePayment`, {
        lineItems, userId, email, paymentId,
      });
    } catch (err) {
      console.log('send Cart Items err: ', err);
    }
    setLoading(false);
  };
  useEffect(() => {
    getShopItems();
  }, []);

  useEffect(() => {
    getShopItems();
  }, []);

  return (
    <Context.Provider
      value={{
        ...state,
        addCartItem,
        removeCartItem,
        clearCartItems,
        getChargeInfo,
        setCartItems,
        setIsSidebarOpen,
        sendCartItems,
        setDataStripe,
        getUserData,
        restoreCartItem,
      }}
    >
      {children}
    </Context.Provider>
  );
};
