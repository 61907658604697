import React from 'react';
import styled from 'styled-components';
import queryString from 'query-string';

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dc3545;
  color: #fff;
  padding: 10px;
  font-family: 'Circular-Std-Book';
  text-transform: uppercase;
  font-weight: 100;
  font-size: 12px;
`;

const Error = () => (
  <ErrorWrapper>
    <p className="caWrn">El pago no fue exitoso, por favor intenta de nuevo. Si el error persiste, manda correo a soporte@madyfit.com.</p>
  </ErrorWrapper>
);
const Banner = () => {
  const parsed = queryString.parse(window.location.search);

  return (
    <>
      {parsed.error === 'true' && <Error />}
    </>
  );
};

export default Banner;
