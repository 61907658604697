import axios from 'axios';
import { users } from './users';
import { routines } from './routines';
import { dietaryPlans } from './dietary-plans';
import { billings } from './billings';
import { coupons } from './coupons';

const API_VERSION = '';
// const API_VERSION = 'v2/';

class ApiWrapper {
  constructor({ token, baseUrl }) {
    this.token = null;
    this.baseUrl = null;
    this.httpInstance = null;

    this.createHttpInstance();
    this.setToken(token);
    this.setBaseUrl(baseUrl);
  }

  createHttpInstance() {
    this.httpInstance = axios.create({
      baseURL: this.baseUrl,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'applicacion/json',
        Authorization: `Bearer ${this.token}`,
      },
    });

    this.httpInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          window.location.replace('/');
        }

        Promise.reject(error);
      },
    );
  }

  setBaseUrl(baseUrl = 'localhost:8081/') {
    this.httpInstance.defaults.baseURL = `${baseUrl}${API_VERSION}`;
    this.baseUrl = `${baseUrl}${API_VERSION}`;
  }

  setToken(token) {
    this.token = token;
    this.httpInstance.defaults.headers.Authorization = `Bearer ${this.token}`;
  }
}

Object.assign(ApiWrapper.prototype, {
  ...users,
  ...routines,
  ...dietaryPlans,
  ...billings,
  ...coupons,
});

export default ApiWrapper;
