import React, { useEffect, useState } from 'react';
import {
  Redirect, Route, Switch, useHistory,
} from 'react-router';
import { isEmpty } from 'lodash';
import queryString from 'query-string';
import { useAuth0 } from '@auth0/auth0-react';
import Header from '../Header/Header';
import Hero from '../Hero/Hero';
import Footer from '../Footer/Footer';
import ProductsList from '../Products/ProductsList';
import Sidebar from '../Sidebar/Sidebar';
import Account from '../Account/Account';
import DeleteAccount from '../Account/DeleteAccount';
import ProductSingle from '../ProductSingle/ProductSingle';
import Banner from '../Header/Banner';
import ApiWrapper from '../../helpers/api/api-wrapper';
import Loading from '../Utility/Loading';
import Checkout from '../Checkout/Checkout';
import SuccessfulPayment from '../Checkout/SuccessfulPayment';
import { useShop } from '../../contexts/shop';

const Admin = () => {
  const { push } = useHistory();
  const [isLoadingApiWrapper, setIsLoadingApiWrapper] = useState(true);
  const { getAccessTokenSilently, user: { email, sub: userId } = {}, user } = useAuth0();
  const { sendCartItems, setCartItems } = useShop();
  let parsed = queryString.parse(window.location.search);
  useEffect(() => {
    (async () => {
      try {
        setIsLoadingApiWrapper(true);

        const token = await getAccessTokenSilently();
        //ApiWrapper.setToken(token);
        let mToken = localStorage.getItem('token_shop');
        if (!mToken && userId && email) {
          const { data: token } = await ApiWrapper.getToken(userId, email);
          localStorage.setItem('token_shop', token.token);
          ApiWrapper.setToken(token.token);
        } else {
          ApiWrapper.setToken(mToken);
        }
        if (isEmpty(parsed.lineItems)) {
          const data_parameters = window.location.href.substring(window.location.href.lastIndexOf('#lineItems') + 1);
          parsed = queryString.parse(data_parameters);
        }
        // re-add line items to state if success
        if (!isEmpty(parsed.lineItems) && parsed.success !== 'true') {
          setCartItems(JSON.parse(decodeURIComponent(parsed.lineItems)));
          delete parsed.lineItems;
          push(`/?${queryString.stringify(parsed)}`);
        }
        // add items to user in api
        if (!isEmpty(parsed.lineItems) && !isEmpty(parsed.payment_intent) && parsed.success === 'true') {
          // TODO: add billing call
         
          const items = JSON.parse(decodeURIComponent(parsed.lineItems));
          await ApiWrapper.sendCartItems(items, userId === null ? userId : parsed.userId, email, parsed.payment_intent);
          delete parsed.lineItems;
          delete parsed.payment_intent;
          delete parsed.payment_intent_client_secret;
          delete parsed.redirect_status;
          push(`/?${queryString.stringify(parsed)}`);
        }
        if (parsed.success === 'true') {
          push('/successfulpayment');
        }
        setIsLoadingApiWrapper(false);
      } catch (err) {
        setIsLoadingApiWrapper(false);

        if (err.error === 'login_required') {
          return console.log('login with redirect');
        }
        if (err.error === 'consent_required') {
          return console.log('login with redirect');
        }

        throw err;
      }
    })();
  }, []);

  if (isLoadingApiWrapper) return <Loading />;

  return (
    <>
      <Banner />
      <Header />
      <Switch>
        <Route path="/" exact>
          <Hero />
          <ProductsList />
        </Route>
        <Route path="/cuenta" exact>
          <Hero />
          <Account />
        </Route>
        <Route path="/eliminarcuenta" exact>
          <Hero />
          <DeleteAccount />
        </Route>
        <Route path="/checkout" exact>
          <Checkout />
        </Route>
        <Route path="/successfulpayment" exact>
          <SuccessfulPayment />
        </Route>
        <Route path="/:productId">
          <ProductSingle />
        </Route>
        <Redirect path="/" />
      </Switch>
      <Footer />
      <Sidebar />
    </>
  );
};

export default Admin;
