import React from 'react';
import { isNil } from 'lodash';
import styled from 'styled-components';
import ProductsListItem from './ProductsListItem';
import { useShop } from '../../contexts/shop';
import Loading from '../Utility/Loading';
import { MEAL_PLAN_DEFAULT } from '../../helpers/constants';

const Grid = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(1, 1fr);
  padding: 1rem;
  max-width:30rem;
  margin: auto;

  @media only screen and (min-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
    max-width: unset;
    grid-gap: 2rem;
    padding: 1rem 3rem;
  }

  @media only screen and (min-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (min-width: 1500px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const ProductsList = () => {
  const { shopItems } = useShop();
  if (isNil(shopItems)) return <Loading />;
  return (
    <Grid>
      <ProductsListItem
        price={MEAL_PLAN_DEFAULT.price}
        id={MEAL_PLAN_DEFAULT.id}
        key={MEAL_PLAN_DEFAULT.id}
        title={MEAL_PLAN_DEFAULT.title}
        media={MEAL_PLAN_DEFAULT.media}
        shortDescription={MEAL_PLAN_DEFAULT.shortDescription}
      />
      {
        shopItems.map((product) => (
          <ProductsListItem
            price={product.price}
            id={product.id}
            key={product.id}
            title={product.title}
            media={product.media}
            shortDescription={product.shortDescription}
          />
        ))
      }
    </Grid>
  );
};

export default ProductsList;
