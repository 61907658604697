import React from 'react';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';

const Grid = styled.div`


  @media only screen and (min-width: 700px) {

  }

  @media only screen and (min-width: 1000px) {

  }

  @media only screen and (min-width: 1500px) {

  }
`;

const SuccessfulPayment = () => {
  const { user: { email } = {} } = useAuth0();
  return (
    <Grid>
      <div className="caSuccesfullMessage">
        <h5>¡Tu compra se ha realizado con éxito!</h5>
        <p>
          Ya podrás visualizar tus compras iniciando sesión en la app MadyFit desde tu iOS o Android. Recuerda que debes ingresar con el correo con el que realizaste tu compra:
          <span>{` ${email}`}</span>
        </p>
        <ul>
          <li className="caMacStore"><a href="https://play.google.com/store/apps/details?id=com.fersa.madydaily" target="_blank" rel="noreferrer">&nbsp;</a></li>
          <li className="caGooglePlay"><a href="https://apps.apple.com/mx/app/madyfit/id1567857008?l=en" target="_blank" rel="noreferrer">&nbsp;</a></li>
        </ul>
      </div>
    </Grid>
  );
};

export default SuccessfulPayment;
