import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { Button, Modal,  Input } from 'antd';
import styled from 'styled-components';
import ApiWrapper from '../../helpers/api/api-wrapper';
import { useAuth0 } from '@auth0/auth0-react';

const AlertText = styled.p`
  color: red;
`;

const ModalConfirmedEmail = ({showModal}) => {
  const { user: { sub: userId } = {} } = useAuth0();
  const [open, setOpen] = useState(showModal);
  const [email, setEmail] = useState('');
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleOk = async (e) => {
    e.preventDefault()
    if(email) {
        setConfirmLoading(true);
        const { data } = await ApiWrapper.saveEmail(userId, email);
        if(data.message === '') {
            setOpen(false);
            setConfirmLoading(false);
        }
        else {
            setMessage(data.message ? data.message : 'Ocurrio un error favor de intentar nuevamente.')
            setConfirmLoading(false);
        }     
      }
      else {
        setMessage('Favor de introducir un correo')
      }
  };

  const handleCancel = () => setOpen(false);
  return (
    <>
      <Modal
        title="Confirmación de correo"
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText="Aceptar"
        cancelText="Cancelar"
      >

        <p>
          Favor de capturar un correo válido para hacerle llegar los
          comprobantes de sus compras, así como avisos y promociones.
        </p>
        <br />
        <Input value={email} addonBefore="Correo Electrónico" onChange={({ target: { value } }) => setEmail(value)} />
        <AlertText>{message}</AlertText>
      </Modal>
    </>
  );
};

export default ModalConfirmedEmail;
