import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import ErrorBoundary from './components/Utility/ErrorBoundary';
import Admin from './components/Admin/Admin';
import Loading from './components/Utility/Loading';
import { ShopProvider } from './contexts/shop';

const App = () => {
  const { isLoading } = useAuth0();

  if (isLoading) return <Loading />;

  return (
    <ErrorBoundary>
      <ShopProvider>
        <Router>
          <Switch>
            <Route component={Admin} />
          </Switch>
        </Router>
      </ShopProvider>
    </ErrorBoundary>
  );
};

export default App;
