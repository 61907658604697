import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Facebook } from '../../assets/svg/facebook.svg';
import { ReactComponent as Twitter } from '../../assets/svg/twitter.svg';
import { ReactComponent as Instagram } from '../../assets/svg/instagram.svg';

const FooterWrapper = styled.footer`

  padding: 3rem 1rem;

  @media only screen and (min-width: 700px) {

  }
`;

const Section = styled.section`
  flex: 1;
`;

const Article = styled.article`

`;

const Form = styled.form`
  display: flex;
`;

const Header = styled.h3`
  padding-bottom: 16px;
  font-family: 'TiemposDisplay-Medium';
  line-height: 1.33333em;
  font-size: 20px;
`;

const FooterText = styled.h3`
  padding-bottom: 16px;
  font-family: 'Circular-Std-Book';
  font-weight: 400;
`;

const SocialIcon = styled.span`
  padding: 8px;
`;

const Button = styled.button`
margin: 0 0 0 8px;
border-radius: 2px;
background-color: #000;
color: #fff;
outline:none;
font-size: 14px;
text-transform: uppercase;
border-radius: 2px;
border: none;
padding: 0 12px;
`;

const Input = styled.input`
  height: 45px;
  line-height: 45px;
  padding-left: 10px;
`;

const Footer = () => (
  <FooterWrapper>
    <Section>
      <Header>MadyFit</Header>
      <FooterText>¡Bienvenida a la #GluteGuideGang!</FooterText>
      <FooterText>MadyFit © 2024</FooterText>
      <div>
        <SocialIcon>
          <Facebook style={{ height: 16 }} />
        </SocialIcon>
        <SocialIcon>
          <Twitter style={{ height: 16 }} />
        </SocialIcon>
        <SocialIcon>
          <Instagram style={{ height: 16 }} />
        </SocialIcon>
      </div>
    </Section>
    <Section>
      <Article>
        <Header>Newsletter</Header>
        <FooterText>Suscríbete al Newsletter para enterarte de noticias y promociones.</FooterText>
        <Form>
          <Input placeholder="Tu Email" />
          <Button>
            ok
          </Button>
        </Form>
      </Article>
    </Section>
  </FooterWrapper>
);

export default Footer;
