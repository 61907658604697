import { isNil } from 'lodash';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { useShop } from '../../contexts/shop';
import Loading from './Loading';

const ProductButton = styled.button`
    background: #574cff;
    border: none;
    color: #FFF;
    font-size: 14px;
    padding: 0 26px;
    height: 36px;
    border-radius: 100rem;
    cursor: pointer;
    font-family: 'Circular-Std-Book';
    transition: .3s ease all;
    :hover{
      background-color:#2e21e6 !important;
    }
`;

const AddProductButton = ({
  id, title, children, isMealPlan, variation, mainRoutine, ...props
}) => {
  const {
    addCartItem, setIsSidebarOpen, shopItemsDietaryPlans, shopItems,
  } = useShop();
  const [addingItem, setAddingItem] = useState();
  const { push } = useHistory();

  const handleClick = async () => {
    if (isMealPlan) return push(`/${id}?callout=true`);
    if (addingItem) return;

    setAddingItem(true);

    const findPlanById = (id, items) => items.find((item) => item.id === id);

    const findMainRoutineById = (id, mainRoutine) =>
      mainRoutine.main ? mainRoutine.grouped_focuses.find((item) => item.Routine_id === id) : null;

    const plan = variation
      ? findPlanById(id, shopItemsDietaryPlans)
      : findMainRoutineById(id, mainRoutine) || findPlanById(id, shopItems);
    
    // const plan = variation ? shopItemsDietaryPlans.find((item) => item.id === id)
    //   : ((mainRoutine && mainRoutine.main) ? mainRoutine.grouped_focuses.find((item) => item.Routine_id === id) : shopItems.find((item) => item.id === id));

    if (isNil(plan)) return <Loading />;

    let {
      price, media, title, type, subTitle = ""
    } = plan;

    if((mainRoutine && mainRoutine.main)) {
      price = plan.routine.price;
      media = plan.routine.media;
      title = plan.routine.RoutineTranslations[0].title;
      subTitle = plan.workout;
      type = "exercise'";
    }
    addCartItem({
      id, quantity: 1, variation, title, price, media, type, subTitle,
    });

    await new Promise((resolve) => setTimeout(resolve, 500));

    setIsSidebarOpen(true);

    setAddingItem(false);
  };

  return (
    <ProductButton onClick={handleClick} type="button" {...props}>
      {
        addingItem ? 'Loading...' : children
      }
    </ProductButton>
  );
};

export default AddProductButton;
