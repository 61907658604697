export const PERMISSIONS_KEY = 'https://madydaily.com/roles';

export const OMNIVORO = 'Omnívoro';
export const VEGETARIANO = 'Vegetariano';
export const VEGANO = 'Vegano';

export const PLAN_TYPE_OPTIONS = [OMNIVORO, VEGETARIANO, VEGANO];

export const PURPLE = '#574CFF';
export const PINK = '#FF99CC';
export const SALMON = '#FFC5C5';

export const MEAL_PLAN_KEYWORD = 'Alimenticio';
export const KEYWORD_UNIQUE_ROUTINE = 'unique';

export const MEAL_PLAN_DEFAULT = {
  price: 500,
  id: 0,
  title: 'Plan Alimenticio',
  media:
    'https://cdn.shopify.com/s/files/1/0042/6750/7776/files/Pancake.jpg?v=1668476007',
  shortDescription:
    'Elige tu plan alimenticio omnívoro, vegano o vegetariano. Todos hechos por nuestro equipo de nutriologxs.',
};

export const MEAL_PLAN_DEFAULT_SELECT = {
  calorie: 0,
  description: `
  <p><b>En MadyFit todos los programas alimenticios son semi-personalizados y creados por nuestra nutrióloga certificada, Tania Cureño.</b></p>
  <p style="margin:0px;">Los planes alimenticios que ofrecemos son semi-personalizados. Por esta razón, si padeces alguna patología (por ej. diabetes) necesitarás acudir con unx nutriólogx para que te diseñe un plan 100% personalizado. Lo mismo aplica para los programas de entrenamiento: son muy buena opción si no necesitas algo diseñado especialmente para ti, pero si tienes alguna condición particular que requiera de cambios o de una programación específica, mis guías no serán la opción indicada para ti. Esto es muy importante que lo tomes en cuenta para cuidar tu salud.</p>
  <p style="margin:0px;">Nuestros planes duran un mes, y cada día tiene recetas diferentes, con el fin de que tengas muchas opciones, pero puedes repetir un mismo día varias veces si te gusta mucho. Lo importante es cumplir con las calorías del día. El plan puedes repetirlo varios meses, hasta que quieras cambiar de etapa/meta (bajar grasa, mantenerte, o aumentar músculo). Solo recuerda que no debes durar en déficit calórico (dieta para bajar grasa) más de 4 meses, si al cuarto mes quieres seguir bajando, necesitas tomarte un break para poder retomar la pérdida de grasa. En cada comida y snack verás cuántos gramos de proteína y calorías contiene dicha comida, y una vez por semana tendrás una comida libre.</p>
  
  <p style="margin:0px;"><b>¿Por qué sirven los planes semi personalizados en personas sanas / libres de patologías?</b></p>
  <p style="margin:0px;">Si eres una persona que no padece alguna enfermedad y buscas mejorar tu composición corporal, lo importante a considerar para tu alimentación, aparte de que sea saludable, es la cantidad de calorías que consumes y la distribución balanceada de macronutrientes.</p>
  <p style="margin:0px;">La cantidad de calorías que consumes es de suma importancia para cumplir tu meta, ya sea esta bajar grasa o aumentar músculo. Las calorías son lo que define si bajas grasa, si te mantienes, o si aumentas músculo. Y estas se calculan con tu peso, estatura, edad y actividad física. Es por esto que nuestros planes se organizan de esta forma y son muy efectivos. ¡Todas necesitamos diferentes calorías!</p>
  
  <p style="margin:0px;"><b>¿Cómo es el proceso de compra para el plan alimenticio si no sé mis calorías?</b></p>
  <p style="margin:0px;">Si quieres incluir un plan alimenticio en tu compra y no sabes cuántas calorías necesitas, antes de hacer tu compra debes mandarnos correo a madydaily@gmail.com diciendo tu peso, estatura, edad y cuántos días por semana harás ejercicio (o cuál guía harás). También menciona qué quieres hacer: cut (bajar grasa), recomp (mantenerte), o bulk (aumentar músculo). Si no estás segura, incluye fotos tuyas actuales y te damos nuestra recomendación. Te responderemos con las calorías que necesitas, y con eso ya puedes realizar tu compra.</p>`,
  id: 0,
  media:'https://madyfit-assets-prod.s3.amazonaws.com/1641517027263-Screen%20Shot%202022-01-06%20at%2018.56.58.png',
  price: 0,
  title: 'Plan Alimenticio',
};
