import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import './ScrollableTabsContainerStyle.css';

const VariationButton = styled.button`
  border: 1px solid #dadce0;
  border-color: ${({ isActive }) => (isActive ? '#574cff !important' : '#dadce0')};
  background: ${({ isActive }) => (isActive ? '#574cff !important' : '#fff')};
  color: ${({ isActive }) => (isActive ? '#FFF' : '#202124')};
  border-style: solid;
  text-decoration: none;
  padding: 7px 15px;
  display: inline-block;
  border-radius: 100px;
  user-select: none;
  white-space: nowrap;
  cursor: pointer;
  text-transform: lowercase;
`;

const ScrollableTabsContainer = ({
  secondaryVariation,
  secondaryOptions,
  onSecondaryChange,
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleTabClick = (tab) => {
    removeAllActiveClasses();
    tab.classList.add('active');
  };

  const removeAllActiveClasses = () => {
    const tabs = document.querySelectorAll('.scrollable-tabs-container a');
    tabs.forEach((tab) => {
      tab.classList.remove('active');
    });
  };

  const manageIcons = () => {
    const tabsList = document.querySelector('.scrollable-tabs-container ul');
    const leftArrowContainer = document.querySelector(
      '.scrollable-tabs-container .left-arrow',
    );
    const rightArrowContainer = document.querySelector(
      '.scrollable-tabs-container .right-arrow',
    );

    if (tabsList.scrollLeft >= 20) {
      leftArrowContainer.classList.add('active');
    } else {
      leftArrowContainer.classList.remove('active');
    }

    const maxScrollValue = tabsList.scrollWidth - tabsList.clientWidth - 20;

    if (tabsList.scrollLeft >= maxScrollValue) {
      rightArrowContainer.classList.remove('active');
    } else {
      rightArrowContainer.classList.add('active');
    }
  };

  const handleRightArrowClick = () => {
    const tabsList = document.querySelector('.scrollable-tabs-container ul');
    tabsList.scrollLeft += 200;
    manageIcons();
  };

  const handleLeftArrowClick = () => {
    const tabsList = document.querySelector('.scrollable-tabs-container ul');
    tabsList.scrollLeft -= 200;
    manageIcons();
  };

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const tabsList = document.querySelector('.scrollable-tabs-container ul');
    tabsList.classList.add('dragging');
    tabsList.scrollLeft -= e.movementX;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    const tabsList = document.querySelector('.scrollable-tabs-container ul');
    tabsList.classList.remove('dragging');
  };
  const handleResize = () => manageIcons();

  useEffect(() => {
    const tabs = document.querySelectorAll('.scrollable-tabs-container a');
    const rightArrow = document.querySelector(
      '.scrollable-tabs-container .right-arrow svg',
    );
    const leftArrow = document.querySelector(
      '.scrollable-tabs-container .left-arrow svg',
    );
    const tabsList = document.querySelector('.scrollable-tabs-container ul');

    tabs.forEach((tab) => {
      tab.addEventListener('click', () => handleTabClick(tab));
    });

    rightArrow.addEventListener('click', handleRightArrowClick);
    leftArrow.addEventListener('click', handleLeftArrowClick);
    tabsList.addEventListener('scroll', manageIcons);

    tabsList.addEventListener('mousedown', handleMouseDown);
    tabsList.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    manageIcons();
    window.addEventListener('resize', handleResize);
    return () => {
      tabs.forEach((tab) => {
        tab.removeEventListener('click', () => handleTabClick(tab));
      });

      rightArrow.removeEventListener('click', handleRightArrowClick);
      leftArrow.removeEventListener('click', handleLeftArrowClick);
      tabsList.removeEventListener('scroll', manageIcons);

      tabsList.removeEventListener('mousedown', handleMouseDown);
      tabsList.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      window.addEventListener('resize', handleResize);
    };
  }, [isDragging]);

  return (
    <div className="scrollable-tabs-container">
      <div className="left-arrow">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15.75 19.5L8.25 12l7.5-7.5"
          />
        </svg>
      </div>

      <ul>
        {secondaryOptions.map((option) => (
          <li>
            <VariationButton
              key={option}
              type="button"
              onClick={() => onSecondaryChange(option)}
              isActive={secondaryVariation === option}
            >
              {option}
            </VariationButton>
          </li>
        ))}
      </ul>
      <div className="right-arrow active">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M8.25 4.5l7.5 7.5-7.5 7.5"
          />
        </svg>
      </div>
    </div>
  );
};

export default ScrollableTabsContainer;
