import React from 'react';
import { EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateFromHTML } from 'draft-js-import-html';
import htmlToDraft from 'html-to-draftjs';

const getInitialEditorState = (initialValue) => {
  try {
    const blocksFromHtml = htmlToDraft(initialValue || '<p></p>');
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap,
    );
    return EditorState.createWithContent(contentState);
  } catch (err) {
    console.log(`AQUI ERROR: ${err}`);
    return EditorState.createWithContent(stateFromHTML(initialValue));
  }
};

const Rendered = ({ html, ...props }) => {
  const editorState = getInitialEditorState(html);

  return (
    <>
      <div className="wysiwyg">
        <Editor
          {...props}
          toolbar={{
            options: [],
          }}
          readOnly
          className={props.className}
          editorState={editorState}
        />
      </div>
    </>
  );
};

export default Rendered;
