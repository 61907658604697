// import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export const createCheckoutSession = async (amount, billingId) => {
  // const stripe = await stripePromise;
  const amountInCents = Math.round(amount * 100);
  console.log(amountInCents);
  try {
    // eslint-disable-next-line max-len
    const { data: { id: clientSecret } } = await axios.post(`${process.env.REACT_APP_NETLIFY_BASE_URL}/create-checkout-session`, {
      amount: amountInCents,
      billingId,
    });
    return clientSecret;
  } catch (err) {
    console.log('err createCheckoutSession', err);
    return '';
  }
  // const result = await stripe.redirectToCheckout({
  //   sessionId,
  // });

  // if (result.error) console.log('error: ', result.error);
};
