import { isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
// import { loadStripe } from '@stripe/stripe-js';
import { useShop } from '../../contexts/shop';
import SidebarItem from './SidebarItem';
import { createCheckoutSession } from '../../helpers/billing';
import ApiWrapper from '../../helpers/api/api-wrapper';
import deleteLogo from '../../assets/svg/delete.svg';
import { notification } from 'antd';

const SidebarWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  width: 300px;
  z-index: 10;
  background-color: #fff;
  height: 100vh;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: all .3s;

  ${({ isOpen }) => (!isOpen ? `
      right: -400px;
    ` : '')}
`;

const Close = styled.div`
position: absolute;
    top: 9px;
    left: 12px;
    cursor: pointer;
    font-size: 15px;
    background-color: rgb(232 232 232 / 80%);
    width: 30px;
    height: 30px;
    border-radius: 100rem;
    font-weight: bold;
    text-align: center;
    line-height: 2;
    color: #7f7f7f;
    transition: .3s ease all;
    :hover{
      background-color: rgb(167 167 167 / 80%);
      color: #FFF;
    }
`;

const Header = styled.h3`
  font-family: 'TiemposDisplay-Medium' !important;
  font-size: 1.5rem;
  text-align: left;
  color: #574cff;
  margin-top: 3.5rem;
  padding-left: 12px;
  margin-bottom: 0.5rem;
`;

const CheckoutButton = styled.button`
    background: #574cff;
    border: 0;
    font-size: 1rem;
    border-radius: 100rem;
    cursor: pointer;
    margin-top: auto;
    flex: 0;
    padding: 10px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    color: #FFF;
    margin-bottom: 1rem;
    margin-top: 12px;
    transition: .3s ease all;
    :hover{
      background-color:#2e21e6;
    }
`;

const CouponRedemptionButton = styled.button`
  background: #2e21e6;
  border: 0;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  margin-top: auto;
  flex: 0;
  padding: 10px;
  width: 100px;
  margin-left: 10px;
  color: #FFF;
  transition: .3s ease all;
  :hover{
    background-color:#574cff;
  },
  ${(props) =>
    props.disabled &&
    `
    :disabled {
      background-color: #9e99ff;
      cursor: not-allowed;
    }
  `}
`;
const Input = styled.input`
  height: 40px;
  width: 150px;
  border-color: #574cff;
  border-radius: 5px;
  margin-left: 10px;
  placeholder: Cupón;
  font-size: 1rem;
  color: #574cff;
`;

const DeleteButton = styled.button`
    border: 0;
    background-color: transparent;
    margin: 0;
    padding: 0;
    margin-top: 0;
    text-align: left;
    cursor: pointer;
    text-decoration: underline;
    color: red;`;

const Sidebar = () => {
  const {
    isSidebarOpen,
    isAddCartItem,
    setIsSidebarOpen,
    cartItems,
    setDataStripe,
    userData,
    getUserData,
    setCartItems,
    restoreCartItem,
  } = useShop();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const [coupon, setCoupon] = useState('');
  const [couponError, setCouponError] = useState('');
  const [couponData, setCouponData] = useState({});
  const [cartTotalMXN, setCartTotalMXN] = useState(0);
  const [hasCouponApplied, setHasCouponApplied] = useState(false);
  const { isAuthenticated, loginWithRedirect, user: { email, sub: userId } = {} } = useAuth0();
  const [isCheckingOut, setIsCheckingOut] = useState(false);
  const [checkCouponRemoveItem, setCheckCouponRemoveItem] = useState(false);

  const mToken = localStorage.getItem('token_shop');
  useEffect(() => {
    if (email && mToken) {
      getUserData(userId, email);
    }
  }, [mToken]);

  const calculateOrderAmountMXN = (items) => {
    let quantity=0;
    let amount = items
      .map((item) => {
        if (item.total_with_coupon) {
          quantity += item.quantity;
          if(couponData?.coupon_percentage) {
            return item.total_with_coupon * item.quantity;
          }
        }
        return item.price * item.quantity;
      })
      .reduce((prev, curr) => prev + curr, 0);
    if (couponData?.coupon_amount) {
      amount = amount - (couponData?.coupon_amount * quantity);
    }
    const isNotGeneralCoupon = items.some(item => 'total_with_coupon' in item)
    if (couponData?.coupon_percentage && !isNotGeneralCoupon) {
      amount = amount - (amount * couponData?.coupon_percentage / 100);
    }
    return amount.toFixed(2);
  };

  const onCheckout = async () => {
    const urlError = '/?error=true';
    let urlResponse = '/checkout';
    try {
      setIsCheckingOut(true);
      const { billingId } = userData;
      const result = await createCheckoutSession(calculateOrderAmountMXN(cartItems), billingId);
      setDataStripe(result);
      if (!result) {
        setIsCheckingOut(false);
        urlResponse = urlError;
      }
    } catch (err) {
      setIsCheckingOut(false);
      urlResponse = urlError;
    }
    push(urlResponse);
  };

  const handleCouponRedemption = async () => {
    setCouponError('');
    const cartDetailsForRequest = cartItems.map((item) => {
      return {        
        ...item,
        routine_id: item.type === 'exercise' ? item.id : null,
        dietary_plan_id: item.type !== 'exercise' ? item.id : null,
        total_with_coupon: null,
      }
    });
    const couponRedemptionResponse = await ApiWrapper.redeemCoupon(
      cartDetailsForRequest,
      coupon,
      userId,
      email,
      calculateOrderAmountMXN(cartItems),
    );
    if (couponRedemptionResponse && couponRedemptionResponse.status === 200) {
      if (couponRedemptionResponse.data && couponRedemptionResponse.data.error_message) {
        setCouponError(couponRedemptionResponse.data.error_message);
        return;
      }
      if (couponRedemptionResponse.data.response && couponRedemptionResponse.data.response.cart_details) {
        setHasCouponApplied(true);
        setCouponData(couponRedemptionResponse.data.response);
        setCartItems(couponRedemptionResponse.data.response.cart_details);
        // notification.success({
        //   message: 'Cupón aplicado',
        //   description: `El cupón ${coupon} se ha aplicado correctamente`,
        //   placement: 'top',
        // });
      }
    }
  };

  const handleLoginAndCheckout = () => {
    try {
      setIsCheckingOut(true);

      loginWithRedirect({
        screen_hint: 'login',
        appState: {
          checkout: true,
          cartItems,
        },
        fragment: `lineItems=${JSON.stringify(cartItems)}`,
      });
    } catch (err) {
      setIsCheckingOut(false);
    }
  };

  useEffect(() => {
    if (pathname === '/checkout') {
      push('/');
    }
    setDataStripe({});
    setIsCheckingOut(false);
    if (cartItems.length === 0) {
      setCoupon('');
      setCouponData({});
      setHasCouponApplied(false);
    }
    const hasCoupon = cartItems.some(item => 'total_with_coupon' in item) || couponData?.total_with_coupon;
    if (!hasCoupon) {
      setCoupon('');
      setCouponData({});
      setHasCouponApplied(false);
    }
    setCartTotalMXN(calculateOrderAmountMXN(cartItems));
    if(coupon !== '' && (checkCouponRemoveItem || isAddCartItem)) {
      setCheckCouponRemoveItem(false);
      setHasCouponApplied(false);
      restoreCartItem();
      handleCouponRedemption();
    } else {
      setCouponError('');
    }
  }, [cartItems]);

  const handleDeleteCoupon = () => {
    setCoupon('');
    setHasCouponApplied(false);
    setCouponData({});
    setCartItems(cartItems.map(item => {
      const { total_with_coupon, ...newItem } = item;
      return newItem;
    }))
  };
  return (
    <SidebarWrapper isOpen={isSidebarOpen}>
      <Close onClick={() => setIsSidebarOpen(false)}>X</Close>
      <Header>Tu Carrito</Header>
      <div className="caProductList">
        {
          isEmpty(cartItems) && <p style={{ fontFamily: 'Circular-Std-Book', textAlign: 'center', marginTop: '4rem' }}>Tu carrito esta vacio</p>
        }
        {
          cartItems.map(({
            id,
            quantity,
            type,
          }) => <SidebarItem key={`${type}${id}`} id={id} quantity={quantity} type={type} couponAmount={couponData?.coupon_amount} couponPercentage={couponData?.coupon_percentage} setCheckCouponRemoveItem={setCheckCouponRemoveItem}/>)
        }
        {
          !isEmpty(cartItems) && !hasCouponApplied && isAuthenticated && <>
            <Input placeholder='Cupón' value={coupon} onChange={(e) => setCoupon(e.target.value)}></Input>

            <CouponRedemptionButton disabled={!coupon} onClick={handleCouponRedemption}>
              Aplicar
            </CouponRedemptionButton>
            {
              couponError && <p style={{ color: 'red', fontSize: '12px', marginTop: '5px', textAlign: 'center' }}>{couponError}</p>
            }
          </>
        }
        {
          !isEmpty(cartItems) && cartItems.length >= 1 && hasCouponApplied && isAuthenticated && <>
            <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
              <label style={{ marginLeft: '10px', fontSize: '14px', marginTop: '5px', fontWeight: 'bold' }}>{coupon}</label>

              <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'end', width: '7%' }}>
                <DeleteButton onClick={handleDeleteCoupon}><img src={deleteLogo} alt="Logo" style={{ width: '15px', paddingTop: '5px', }} /></DeleteButton>
              </div>

            </div>
            <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
              <label style={{ marginLeft: '10px', fontSize: '12px', fontWeight: 'bold' }}>{`Descuento ${couponData.coupon_discount}`}</label>
            </div>

          </>
        }
        {
          !isEmpty(cartItems) && isAuthenticated && <>
            <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
              <label className="caQty" style={{ marginLeft: '10px', marginTop: '20px' }}>{`Total $${cartTotalMXN} MX`}</label>
            </div>
          </>
        }
      </div>
      {
        isAuthenticated
          ? <CheckoutButton disabled={cartItems.length === 0 || isCheckingOut} onClick={onCheckout}>{isCheckingOut ? 'Loading...' : 'Pagar'}</CheckoutButton>
          : (
            <CheckoutButton onClick={handleLoginAndCheckout} disabled={isCheckingOut}>
              <span>{isCheckingOut ? 'Loading...' : 'Inicia Sesión / Regístrate'}</span>
            </CheckoutButton>
          )
      }
    </SidebarWrapper>
  );
};

export default Sidebar;
