import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from '../Utility/Loading';
import { useShop } from '../../contexts/shop';

const Button = styled.button`
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
`;

const CheckoutForm = () => {
  const { push } = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const { cartItems } = useShop();
  const { user: { sub: userId } = {} } = useAuth0();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_DOMAIN}?success=true&lineItems=${encodeURIComponent(JSON.stringify(cartItems))}&userId=${userId}`,
      },
    });
    console.log('err checkoutForm', error);
    push(`?error=true&lineItems=${encodeURIComponent(JSON.stringify(cartItems))}`);

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      {isLoading ? <Loading />
        : (
          <Button disabled={isLoading || !stripe || !elements} type="submit">
            <span>Realizar Pago</span>
          </Button>
        )}
    </form>
  );
};

export default CheckoutForm;
