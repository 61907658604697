import React from 'react';
import styled from 'styled-components';

const BackgroundImage = styled.img.attrs({
  alt: 'woman fitness instructor',
  src: 'https://cdn.shopify.com/s/files/1/0042/6750/7776/files/madyfit-slide_0aeecfcf-b6d8-43d0-a36c-48a35f89d4cc_1200x.jpg?v=1604339676',
})`
  width: 100%;
  height: 400px;
  object-fit: cover;
  object-position: top;
  display: none;
`;

const HeroWrapper = styled.header`
  position: relative;
`;

const Header = styled.h2`
`;

const Section = styled.section`
  width:100%;
`;

const HeroText = styled.p`
  font-size: 1.2em;
  margin: 0.8em 0;
  color: #fff;
  line-height: 1.5em;
  font-family: 'Circular-Std-Book';
  display: none;
`;

const HeroContent = () => (
  <Section>
    <Header className="caTitle">Programas</Header>

    <HeroText>
      Aumenta glúteos y tonifícate desde tu casa o gimnasio. En la Tienda podrás
      encontrar todo lo necesario para comenzar. Let's grow that booty! ✨
    </HeroText>
  </Section>
);

const Hero = () => (
  <HeroWrapper>
    <BackgroundImage />
    <HeroContent />
  </HeroWrapper>
);

export default Hero;
