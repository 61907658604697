import { useAuth0 } from '@auth0/auth0-react';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as User } from '../../assets/svg/user.svg';

const SidebarWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  max-width: 400px;
  z-index: 10;
  background-color: #fff;
  height: 100vh;
  width: calc(100% - 3rem);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: all .3s;

  ${({ isOpen }) => (!isOpen ? `
      left: -500px;
    ` : '')}
`;

const Close = styled.div`
    position: absolute;
    top: 10px;
    right: -39px;
    cursor: pointer;
    font-size: 20px;
    width: 30px;
    height: 30px;
    border-radius: 100rem;
    font-weight: 500;
    text-align: center;
    line-height: 1.5;
    color: #ffffff;
    transition: .3s ease all;
    :hover{
      color: #FFF;
    }
`;

const Text = styled.p`
    display: block;
    position: absolute;
    width: 25px;
    height: 25px;
    z-index: 10;
    cursor: pointer;
    top: 0;
    left: 0;
`;

const MenuItemLink = styled(Link)`
  font-family: 'Circular-Std-Book';
  letter-spacing: 0;
  text-transform: uppercase;
  font-weight: 100;
  font-size: 14px;
  padding: 20px 35px 20px 15px;
  border-bottom: 1px solid #e0e0e0;
`;

const MenuSidebar = ({ isMenuOpen, setIsMenuOpen }) => {
  const { push } = useHistory();
  const { isAuthenticated, user: { email } = {} } = useAuth0();

  const handleClick = (url) => {
    setIsMenuOpen(false);

    // is not relative url
    if (!url.includes('http')) return push(url);

    window.location.href = url;
  };

  return (
    <SidebarWrapper isOpen={isMenuOpen}>
      <Close onClick={() => setIsMenuOpen(false)} className="caClose">X</Close>
      {isAuthenticated && (
        <MenuItemLink className="caFirstI" style={{ display: 'flex', wordBreak: 'break-all' }} to="/cuenta">
          {' '}
          <User style={{ marginRight: 6, display: 'flex', alignItems: 'center' }} />
          {email}
        </MenuItemLink>
      )}
      <MenuItemLink onClick={() => handleClick('https://madyfit.com/')}>Inicio</MenuItemLink>
      <MenuItemLink onClick={() => handleClick('/')}>Programas</MenuItemLink>
      <MenuItemLink onClick={() => handleClick('https://madyfit.com/collections/all-products')}>Tienda</MenuItemLink>
      <MenuItemLink onClick={() => handleClick('https://madyfit.com/pages/preguntas-frecuentes')}>Preguntas frecuentes</MenuItemLink>
      <MenuItemLink onClick={() => handleClick('https://madyfit.com/pages/calculadora')}>Calculadora</MenuItemLink>
      <MenuItemLink onClick={() => handleClick('https://madyfit.com/blogs/blog')}>Blog</MenuItemLink>
    </SidebarWrapper>
  );
};

const Menu = ({ ...props }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div {...props}>
      <Text onClick={() => setIsMenuOpen(true)}>&nbsp;</Text>
      <MenuSidebar
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
      />
    </div>
  );
};

export default Menu;
