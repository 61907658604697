import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router';
import React from 'react';
import styled from 'styled-components';
import Loading from '../Utility/Loading';
import Routines from './Routines';

const Wrapper = styled.div`
  padding: 0 1rem;
  @media only screen and (min-width: 700px) {
    padding: 0 3rem;
  }
`;

const Header = styled.h3`
  display: block;
  margin-bottom: 3px;
  color: #000;
  font-family: 'Circular-Std-Book';
  font-size: 0.87rem;
`;

const Text = styled.p`
  font-family: 'Circular-Std-Book';
  font-size: .87rem;
  display: block;
  clear: both;
  margin-bottom: 1rem;
`;

const Button = styled.button`
  background: #574cff;
  border: none;
  color: #FFF;
  font-size: 14px;
  padding: 0 26px;
  height: 36px;
  border-radius: 100rem;
  cursor: pointer;
  font-family: 'Circular-Std-Book';
  transition: .3s ease all;
`;

const ButtonDeleteAccount = styled.button`
  background: #fff;
  border: 1px solid #e47f8c;
  color: #e47f8c;
  font-size: 14px;
  padding: 0 26px;
  height: 36px;
  border-radius: 100rem;
  cursor: pointer;
  font-family: 'Circular-Std-Book';
  transition: .3s ease all;
`;

const Account = () => {
  const { push } = useHistory();
  const {
    user, isAuthenticated, isLoading, loginWithRedirect, logout,
  } = useAuth0();

  if (isLoading) return <Loading />;

  if (!isLoading && !isAuthenticated) {
    return loginWithRedirect({
      screen_hint: 'login',
      prompt: 'select_account',
    });
  }

  const { email } = user;

  return (
    <Wrapper>
      <div className="caOverTitle">
        <h6>Mi cuenta</h6>
      </div>
      <div className="caUsr">
        <Header>Usuario</Header>
        <Text>
          {email}
        </Text>
        <Button type="button" onClick={() => logout({ returnTo: window.location.origin })}>Cerrar sesión</Button>
      </div>
      <div className="caBox">
        <h5>¿Necesitas ayuda con algo?</h5>
        <p>Por favor mándanos un correo si tienes algún problema o duda a soporte@madyfit.com</p>
      </div>
      <Routines />
      <div className="caTitle">
        <ButtonDeleteAccount type="button"onClick={() => push('/eliminarcuenta')}>Eliminar cuenta</ButtonDeleteAccount>
      </div>
    </Wrapper>
  );
};

export default Account;
