import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactComponent as User } from '../../assets/svg/user.svg';
import { ReactComponent as Cart } from '../../assets/svg/cart.svg';
import { useShop } from '../../contexts/shop';
import ApiWrapper from '../../helpers/api/api-wrapper';
import ModalConfirmedEmail from '../Utility/ModalConfirmedEmail';
import Menu from '../Menu/Menu';

const HeaderWrapper = styled.header`
  color: #000;
  width: 100%;
  height: 50px;
  background: #574cff;
  z-index: 3;
  top: 0;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 1rem;

  @media only screen and (min-width: 700px) {
    padding: 0 3rem;
  }
`;

const Logo = styled.img.attrs({
  alt: 'madyfit logo',
  src: 'https://cdn.shopify.com/s/files/1/0042/6750/7776/files/madyfit-logo_63da9338-1016-4e0f-b8e4-3316e7e2bb1c_600x200.png?v=1601576427',
})`

  display: block !important; 

  @media screen and (min-width: 700px) {

  }
`;

const HeaderItem = styled.p`
  font-family: 'Circular-Std-Book';
  font-weight: 100;
`;

const CartHeaderItem = styled(HeaderItem)`
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #FFF;
  text-transform: uppercase;
  padding-left:15px;
  position: relative;

  @media only screen and (min-width: 1100px) {
    font-size: 12px;
  }
`;

const Button = styled.button`
  flex: 1;

  font-size: 11px;
  padding: 0 5px;
  height: 25px;
    background: #574CFF;
    border-color: #574CFF;
    color: #fff;
    border-width: 1px;
    border-style: solid;
    border-radius: 0px;
    cursor: pointer;

    @media only screen and (min-width: 1100px) {
    font-size: 12px;
    padding: 0 10px;
    height: 32px;

    }

`;

const SecondaryButton = styled(Link)`
  flex: 1;

  font-size: 11px;
  padding: 0 5px;
  height: 25px;
    background: #574CFF;
    border-color: #574CFF;
    color: #fff;
    border-width: 1px;
    border-style: solid;
    border-radius: 0px;
    cursor: pointer;

    @media only screen and (min-width: 1100px) {
    font-size: 12px;
    padding: 0 10px;
    height: 32px;

  }
`;

const HeaderLink = styled(Link)`
  display: none;
  align-items: center;
  color: #FFF;
  padding: 0 15px;
  text-transform:uppercase;
  font-size: 12px;

  @media only screen and (min-width: 1100px) {
    display: flex;
  }
`;

const ExternalHeaderLink = styled.a`
  display: none;
  align-items: center;
  color: #FFF;
  padding: 0 15px;
  text-transform:uppercase;
  font-size: 12px;

  @media only screen and (min-width: 1100px) {
    display: flex;
  }
`;

// const AccountLink = styled(Link)`
//   display: none;
//   align-items: center;

//   @media only screen and (min-width: 1100px) {
//     display: flex;
//   }
// `;

const HeaderItemText = styled.span`
  display: none;

  @media only screen and (min-width: 1100px) {
    display: inline-block;
  }
`;

const MobileMenu = styled(Menu)`
     display: block;
    position: relative;
    width: 25px;
    height: 20px;
    margin-left:10px;
    margin-right: 5px;

  ::after{
    content: "";
    display: block;
    position: absolute;
    width: 25px;
    height: 16px;
    background-color: #574cff;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    z-index:2;
  }
    ::before{
    content: "";
    display: block;
    position: absolute;
    width: 25px;
    height: 2px;
    background-color: #FFF;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    z-index:5;
  }

  @media only screen and (min-width: 1100px) {
    display: none;
  }
`;

const CartCount = styled.div`
      color: #fff;
    background-color: #9e99ff;
    border-radius: 100rem;
    height: 16px;
    width: 16px;
    display: block;
    font-size: 11px;
    text-align: center;
    line-height: 1.5;
    position: absolute;
    z-index: 10;
    top: -5px;
    left: 27px;
    font-weight: 600;
    box-sizing: border-box;
    padding-right: 1px;
`;

const DropDownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #574cff;
  min-width: 160px;
  color: #FFF;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 2px 2px;
  z-index: 5;
`;

const DropDown = styled.div`
  position: relative;
  display: block;
  color: #FFF;
  &:hover {
    >div {
      display: block;
    }
  }
`;

const Header = () => {
  const [showModal, setShowModal] = useState(false);
  const { cartItems, setIsSidebarOpen } = useShop();
  const { isAuthenticated, loginWithRedirect, logout, user: { email, sub: userId } = {} } = useAuth0();
  const validateUser = async () => {
    if(isAuthenticated) {
      let mToken = localStorage.getItem('token_shop');
      if (!mToken && userId && email) {
        const { data: token } = await ApiWrapper.getToken(userId, email);
        localStorage.setItem('token_shop', token.token);
        ApiWrapper.setToken(token.token);
      } else {
        ApiWrapper.setToken(mToken);
      }
      const { data } = await ApiWrapper.needValidEmail(userId, email);
      setShowModal(data.needValidEmail);
    }
  }
  useEffect(() => {
        validateUser();
  }, []);
  return (
  <>
    <HeaderWrapper>
      <ExternalHeaderLink className="caMainLogo" href="https://madyfit.com/">
        <Logo />
      </ExternalHeaderLink>
      <div className="caHeaderCont">
        <ExternalHeaderLink href="https://madyfit.com/"><HeaderItem>Inicio</HeaderItem></ExternalHeaderLink>
        <HeaderLink to="/"><HeaderItem>Programas</HeaderItem></HeaderLink>
        <ExternalHeaderLink href="https://madyfit.com/collections/all-products"><HeaderItem>Tienda</HeaderItem></ExternalHeaderLink>
        <ExternalHeaderLink href="https://madyfit.com/pages/preguntas-frecuentes"><HeaderItem>Preguntas frecuentes</HeaderItem></ExternalHeaderLink>
        <ExternalHeaderLink href="https://madyfit.com/pages/calculadora"><HeaderItem>Calculadora</HeaderItem></ExternalHeaderLink>
        <ExternalHeaderLink href="https://madyfit.com/blogs/blog"><HeaderItem>Blog</HeaderItem></ExternalHeaderLink>
        <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
          <HeaderItem className="caProf">

            {
              isAuthenticated ? (
                <DropDown>
                  <User style={{ marginRight: 6, display: 'flex', alignItems: 'center' }} />
                  <DropDownContent>
                    <SecondaryButton to="/cuenta">
                      Mi Cuenta
                    </SecondaryButton>
                    <br />
                    <Button onClick={() => logout({ returnTo: window.location.origin })}>
                      Cerrar Sesión
                    </Button>
                  </DropDownContent>
                </DropDown>
              ) : (
                <DropDown>
                  <User style={{ marginRight: 6, display: 'flex', alignItems: 'center' }} />
                  <DropDownContent>
                    <Button onClick={() => loginWithRedirect({
                      screen_hint: 'login',
                      prompt: 'select_account',
                    })}
                    >
                      Iniciar Sesión
                    </Button>
                  </DropDownContent>
                </DropDown>
              )
            }
          </HeaderItem>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
          <CartHeaderItem onClick={() => setIsSidebarOpen(true)}>
            <Cart style={{ marginRight: 12, fontSize: 17 }} />
            <HeaderItemText>Carrito</HeaderItemText>
            {' '}
            {!isEmpty(cartItems) && <CartCount>{cartItems.length}</CartCount>}
          </CartHeaderItem>
        </div>
      </div>
      <MobileMenu />
    </HeaderWrapper>
    
    {showModal ? <ModalConfirmedEmail showModal={showModal}/> : null}
    </>
  );
};

export default Header;
