import { isNil } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { useShop } from '../../contexts/shop';
import Loading from '../Utility/Loading';
import couponLogo from '../../assets/svg/coupon.svg';

const Image = styled.img`
  object-fit: cover;
  width: 6rem;
  height: 6rem;
  border-radius: 14px;
`;

const ProductTitle = styled.h3`
  padding-bottom: 6px;
  font-family: 'TiemposDisplay-Medium' !important;
  color: #000;
  font-size: 1rem;
`;

const ProductSubTitle = styled.h4`
  padding-bottom: 6px;
  font-family: 'TiemposDisplay-Medium' !important;
  color: #000;
  font-size: 1rem;
`;

const ItemWrapper = styled.div`
  display: flex;
  padding: 12px;
`;

const Information = styled.div`
  padding: 4px 0 4px 12px;
  font-family: 'Circular-Std-Book';
  font-size: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Button = styled.button`
    border: 0;
    background-color: transparent;
    margin: 0;
    padding: 0;
    margin-top: 0;
    text-align: left;
    cursor: pointer;
    text-decoration: underline;
    color: gray;`;

const SidebarItem = ({
  id, quantity, type, couponAmount, couponPercentage, setCheckCouponRemoveItem
}) => {
  const { removeCartItem, cartItems } = useShop();

  if (isNil(cartItems)) return <Loading />;

  const item = cartItems.find((item) => item.id === id && item.type === type);

  const { title, media, price, total_with_coupon, variation, subTitle } = item;

  const removeItem = async (id, type) => {
    removeCartItem({ id, type });
    setCheckCouponRemoveItem(true);
  };

  return (
    <ItemWrapper>
      <Image alt="product" src={media} />
      <Information>
        <ProductTitle>{`${title} ${(variation ? `${variation.calories} cal` : '')}`}</ProductTitle>
        <ProductSubTitle>{`${subTitle}`}</ProductSubTitle>
        <span className="caQty">
          Qty:
          {' '}
          {quantity}
        </span>
        <div style={{ display: 'flex' }}>
          <span className="caQty" style={total_with_coupon ? { textDecoration: 'line-through', textDecorationColor: 'gray' } : {}}>
            $
            {price * quantity}
            {' '}
            MX
          </span>
          {
            total_with_coupon && (
              <span className="caQty" style={total_with_coupon ? { color: '#574cff', marginLeft: '5px', fontWeight: 'bold', } : {}}>
                $
                {total_with_coupon && couponAmount ? price * quantity - couponAmount : (total_with_coupon * quantity).toFixed(2)}
                {' '}
                MX
              </span>
            )
          }
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }} className="caQty">
          <Button onClick={() => removeItem(id, type)}>Eliminar</Button>

        </div>

      </Information>
    </ItemWrapper>
  );
};

export default SidebarItem;
