import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { MEAL_PLAN_KEYWORD } from '../../helpers/constants';
import AddProductButton from '../Utility/AddProductButton';
import Title from '../Utility/Title';

const Image = styled.img`
  object-fit: cover;
`;
const ButtonWrapper = styled.div`
  display: block;
`;

const ProductButton = styled.button`
  background: #574cff;
  border: none;
  color: #FFF;
  font-size: 14px;
  padding: 0 16px;
  height: 45px;
  border-radius: 100rem;
  cursor: pointer;
  font-family: 'Circular-Std-Book';
  display: none !important;
`;

const ProductButtonSecondary = styled(ProductButton)`
  background-color: #fff;
  color: #000;
  border-color: #fff;
  display: none;
`;

const Price = styled.p`
  font-family: 'Circular-Std-Book';
  font-size: 14px;
  margin-bottom: 12px;
  text-align:center;
  display: none;
`;

const ProductItemWrapper = styled.div`
  margin-bottom: 18px;
  display: flex;
  flex-direction: column;
`;

const ProductsListItem = ({
  media, id, price, title, shortDescription,
}) => {
  const IS_MEAL_PLAN = title.includes(MEAL_PLAN_KEYWORD);
  return (
    <>
      <ProductItemWrapper>
        <Link className="caToProduct" to={`/${id}`}>
          <Image alt="product" className="caProductImage" src={media} />
        </Link>
        <div className="caTitleSplit">
          <Title>{title}</Title>
          <AddProductButton
            title={title}
            isMealPlan={IS_MEAL_PLAN}
            id={id}
            price={price}
            media={media}
            className="caHidden"
          >
            Agregar
          </AddProductButton>
          <Link className="caToProduct" to={`/${id}`}>
            Ver más
          </Link>
        </div>
        <Price>
          $
          {price}
          {' '}
          MX
        </Price>
        <ButtonWrapper>
          <Link style={{ flex: 1 }} to={`/${id}`}><ProductButtonSecondary type="button">Ver</ProductButtonSecondary></Link>
        </ButtonWrapper>
        <p>{shortDescription}</p>
      </ProductItemWrapper>
    </>
  );
};

export default ProductsListItem;
