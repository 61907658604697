export const billings = {
  sendCartItems(lineItems, userId, email, paymentId) {
    return this.httpInstance.post('/billing/savePayment', {
      lineItems, userId, email, paymentId,
    });
  },
  createPaymentIntent(data) {
    return this.httpInstance.post('/billing/create-payment-intent', data);
  },
};
