import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import styled from 'styled-components';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useShop } from '../../contexts/shop';
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  margin: 0;

  @media only screen and (min-width: 1000px) {
    margin: 0 45px;
    flex-direction: row;
  }
`;

const InfoWrapper = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem;

  @media only screen and (min-width: 1000px) {
    margin: 2rem;
  }
`;

const Checkout = () => {
  const { dataStripe } = useShop();
  const {
    isAuthenticated, loginWithRedirect,
  } = useAuth0();

  if (!isAuthenticated) {
    return loginWithRedirect({
      screen_hint: 'login',
    });
  }

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret: dataStripe,
    appearance,
  };
  return (
    <>
      {dataStripe ? (
        <Elements options={options} stripe={stripePromise}>
          <Wrapper>
            <InfoWrapper>
              <CheckoutForm />
            </InfoWrapper>
          </Wrapper>
        </Elements>
      ) : null}
    </>
  );
};

export default Checkout;
