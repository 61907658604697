import { useAuth0 } from '@auth0/auth0-react';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Loading from '../Utility/Loading';
import ApiWrapper from '../../helpers/api/api-wrapper';

const Header = styled.h3`
  display: block;
  margin-bottom: 3px;
  color: #000;
  font-family: 'Circular-Std-Book';
  font-size: 0.87rem;
`;

const Text = styled.p`
  font-family: 'Circular-Std-Book';
  font-size: .87rem;
  display: block;
  clear: both;
  margin-bottom: 1rem;
`;

const Routines = () => {
  const [user, setUser] = useState();
  const { user: { sub, email } } = useAuth0();

  useEffect(() => {
    (async () => {
      const { data } = await ApiWrapper.getUser(sub, email);
      setUser(data);
    })();
  }, []);

  if (isEmpty(user)) return <Loading />;

  const { UserRoutines, UserDietaryPlans } = user;

  if (isEmpty(UserRoutines) && isEmpty(UserDietaryPlans)) return null;
  console.log(UserDietaryPlans);
  return (
    <>
      <div className="caUsr">
        <Header>Rutinas</Header>
        <p>Recuerda que para visualizar tus compras debes descargar la app MadyFit en tu Iphone y Android.</p>
      </div>
      <div className="caBox">
        {
          UserRoutines.map(
            ({ routine: { RoutineTranslations: [{ title }] } }) => <Text>{title}</Text>,
          )
        }
        {
          UserDietaryPlans.map(({ routine: { calorie, DietaryPlanTranslations: [{ title }] } }) => (
            <Text>
              {`${title} ${calorie} `}
              Cal
            </Text>
          ))
        }
      </div>
    </>
  );
};

export default Routines;
