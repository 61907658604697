export const coupons = {
  
  redeemCoupon(cartDetails, couponTitle, userId, email, total) {
    const fechaHoraActual = new Date();
    return this.httpInstance.post(`/coupons/redeem-coupon`, {
      cart_details: cartDetails,
      coupon_title: couponTitle,
      user_id: userId,
      email,
      total,
      fechaHoraActual
    });
  },
};
