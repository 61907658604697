export const users = {
  getUser(userId, email) {
    return this.httpInstance.get(`/users/${userId}`);
  },
  getDataUser(userId, email) {
    return this.httpInstance.get(`/users/overview/${userId}/${email}`);
  },
  createRoutines(userId, lineItems, email) {
    return this.httpInstance.post(`/users/${userId}/shop-plans`, {
      lineItems,
      email,
    });
  },
  needValidEmail(userId, email) {
    return this.httpInstance.get(`/users/need-valid-email/${userId}/email`);
  },
  saveEmail(userId, confirmedEmail) {
    return this.httpInstance.post(`/users/${userId}/save-email`, {
      confirmedEmail,
    });
  },
  getToken(userId, email) {
    return this.httpInstance.get(`/getToken/${userId}/${email}`);
  },
  deleteUser(userId) {
    return this.httpInstance.delete(`/users/${userId}`);
  },
};
